.tag {

    &--completed,
    &--upcharge {
        background-color: $venmo-1;
        outline: 1px solid $venmo;
        border: 0 !important;
        border-radius: 2px;
    }


    &--inactive {
        outline: 1px solid $warning;
        background-color: $warning-1 !important;
        border: 0 !important;
        border-radius: 2px;
    }

    &--inprogress,
    &--in-progress,
    &--info {
        outline: 1px solid $info;
        background-color: $info-1;
        border: 0 !important;
        border-radius: 2px;

        a {
            color: $primary !important;
        }
    }

    &--active,
    &--scheduled {
        background-color: $microdose-1;
        outline: 1px solid $microdose;
        border: 0 !important;
        border-radius: 2px;
    }

    &--import_source__cloned {
        background-color: $venmo-1;
        outline: 1px solid $venmo;
        border: 0 !important;
        border-radius: 2px;
    }

    &--error__error,
    &--store_closed__closed,
    &--cancelled,
    &--refund {
        background-color: $danger-1 !important;
        outline: 1px solid $danger;
        border: 0 !important;
        border-radius: 2px;
    }
}

.cuboh-tag {
    color: $primary !important;
}
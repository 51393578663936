@import 'variables';
@import 'header';
@import 'mixin';
@import 'sider';
@import 'cubohTag';
@import 'authForm';
@import 'storefront';

body {
	-webkit-font-smoothing: antialiased;
	-webkit-font-feature-settings: 'tnum', 'tnum';
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	font-feature-settings: 'tnum', 'tnum';
	margin: 0 !important;
}

#root,
body,
html {
	height: 100%;
}

table a {
	color: $secondary !important;
}

.cuboh-layout {
	min-height: 100% !important;
}

.main-content {
	margin: 16px 24px 0;
}

.cuboh-layout-sider-trigger {
	display: none;
}

.content-wrapper {
	margin: 24px;
	padding-top: 0;

	.child-content-wrapper {
		margin: -24px -24px 0;
		padding: 24px;
		height: 100%;
	}
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.sider-menu-logo {
	position: relative;
	padding: 0 24px;
	overflow: hidden;
	background: $primary;
	cursor: pointer;
	transition: all 0.3s;

	img {
		display: inline-block;
		height: 32px;
		vertical-align: middle;
	}

	h1 {
		display: inline-block;
		margin: 0 0 0 12px;
		color: #fff;
		font-weight: 600;
		font-size: 20px;
		vertical-align: middle;
		animation: fade-in;
		animation-duration: 0.3s;
	}

	a {
		display: flex;
		align-items: center;
		height: $layout-header-height;
	}
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.login-form-forgot {
	float: right;
}

.login-form-back {
	float: left;
	line-height: 40px;
}

.login-form-button {
	width: 100%;
}

.cuboh-tabs-tab-active {
	background-color: #e6f7ff;
}

.cuboh-menu-item-selected {
	a {
		color: white;
	}
}

.cuboh-menu-item {
	a {
		color: rgba(255, 255, 255, 0.65);

		&:hover {
			color: white;
		}

		&:focus {
			text-decoration: none;
			color: white;
		}
	}
}

.cuboh-menu-submenu-open {
	span {
		color: white;
	}
}

.cuboh-breadcrumb {
	text-transform: capitalize;
}

.cuboh-page-header-heading-title {
	display: inline-block;
	padding-right: 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;
	font-size: 16px;
	line-height: 1.4;
	text-transform: capitalize;
}

.detail-modal {
	.cuboh-modal-content {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.hidden {
	display: None;
}

.fader-enter {
	opacity: 0;
}

.fader-enter-active {
	opacity: 1;
}

.fader-exit {
	opacity: 1;
}

.fader-exit-active {
	opacity: 0;
}

.fader-enter-active,
.fader-exit-active {
	transition: opacity 500ms;
}

.table-top {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
	min-width: 800px;
}

.cuboh-pagination {
	margin-right: 20px !important;
}

.meta-update-inputs {
	width: 80%;
}

.app-avatar {
	box-shadow: 0 0 3px 3px rgb(0 0 0 / 3%);
}

.anticon-check {
	background-color: $success;
	border-radius: 12px;
	color: #fff;
	padding: 6px;
	font-size: 12px;
}

.anticon-close {
	background-color: $danger;
	border-radius: 12px;
	color: #fff;
	padding: 6px;
	font-size: 12px;
}

.menu-management-menu-selector {
	width: 100%;
}

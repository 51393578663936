// Default attribute is 'color'
@mixin color-modifiers($attribute: 'color') {

	// Loop through each of our colors, create a --{color} modifier for the attribute
	@each $name,
	$hex in $colors {
		&--#{'' + $name} {
			#{$attribute}: $hex;
		}
	}
}

$max: 50;
$offset: 5;
$unit: 'px';

@mixin generate-utils($className, $styleName) {
	$i: 0;

	@while $i <=$max {
		#{$className + $i} {
			#{$styleName}: #{$i + $unit};
		}

		$i: $i + $offset;
	}
}

$max: 500;
$offset: 1;
$unit: 'px';

@mixin generate-dimensions($className, $styleName) {
	$i: 15;

	@while $i <=$max {
		#{$className + $i} {
			#{$styleName}: #{$i + $unit};
		}

		$i: $i + $offset;
	}
}

// Margins
@include generate-utils('.mt-', 'margin-top');
@include generate-utils('.mr-', 'margin-right');
@include generate-utils('.mb-', 'margin-bottom');
@include generate-utils('.ml-', 'margin-left');
@include generate-utils('.m-', 'margin');

// Padding
@include generate-utils('.pt-', 'padding-top');
@include generate-utils('.pr-', 'padding-right');
@include generate-utils('.pb-', 'padding-bottom');
@include generate-utils('.pl-', 'padding-left');
@include generate-utils('.p-', 'padding');

// Height / Width
@include generate-dimensions('.height-', 'height');
@include generate-dimensions('.width-', 'width');

.width {
	&-full {
		width: 100%;
	}
}

// Adapted from
// Media-Query Mixin - /https://dev.to/nidhishs/5-scss-mixins-to-check-out-2bkn

@mixin media($from: false, $until: false, $and: false, $media-type: all) {
	$min-width: 0;
	$max-width: 0;
	$query: '';

	//FROM: this breakpoint (inclusive)
	@if $from {
		@if type-of($from)==number {
			$min-width: $from;
		}

		@else {
			$min-width: map-get($breakpoints, $from);
		}
	}

	//UNTIL: this breakpoint (exclusive)
	@if $until {
		@if type-of($until)==number {
			$max-width: $until - 1px;
		}

		@else {
			$max-width: map-get($breakpoints, $until) - 1px;
		}
	}

	@if $min-width !=0 {
		$query: '#{$query} and (min-width: #{$min-width})';
	}

	@if $max-width !=0 {
		$query: '#{$query} and (max-width: #{$max-width})';
	}

	@if $and {
		$query: '#{$query} and (#{$and})';
	}

	@if ($media-type =='all' and $query !='') {
		$media-type: '';
		$query: str-slice(unquote($query), 6);
	}

	@media #{$media-type + $query} {
		@content;
	}
}

// Generates mixins to hide when > a breakpoint
@mixin generate-hidden-from() {

	@each $name,
	$width in $breakpoints {
		.hidden-from--#{$name} {
			@include media($from: $width) {
				display: none;
			}
		}
	}
}

// Generates mixins to hide when < a breakpoint
@mixin generate-hidden-until() {

	@each $name,
	$width in $breakpoints {
		.hidden-until--#{$name} {
			@include media($until: $width) {
				display: none;
			}
		}
	}
}

@include generate-hidden-from();
@include generate-hidden-until();
.header {
	position: relative;
	padding-inline: 20px !important;
	box-shadow: 0 3px 6px rgba(0, 21, 41, 0.08);
	z-index: 2;
	height: $layout-header-height !important;
	background: $primary !important;
	color: white !important;
	cursor: pointer;
}

.header-search {
	padding: 0 12px;

	.anticon-search {
		font-size: 16;
		cursor: pointer;
	}

	.input {
		width: 0;
		background: transparent;
		border-radius: 0;
		border: 0;
		border-bottom: 1px solid #d9d9d9;
		transition: width 0.3s, margin-left 0.3s;
		padding-right: 0;
		padding-left: 0;
		box-shadow: none !important;

		.ant-select-selection {
			background: transparent;
		}

		&,
		&:hover,
		&:focus {
			border-bottom: 1px solid #d9d9d9; //$border-color-base;
		}

		&.show {
			width: 210px;
			margin-left: 8px;
		}
	}
}

.right {
	display: flex;
	float: right;
	align-items: center;
	margin-left: auto;
	height: 100%;
	overflow: hidden;

	.action {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 12px;
		cursor: pointer;
		transition: all 0.3s;

		>i {
			color: $text-color;
			vertical-align: middle;
		}

		&:hover {
			background: $header-hover-bg;
		}

		&:global(.opened) {
			background: $header-hover-bg;
		}
	}

	.account {
		.avatar {
			margin: calc((#{$layout-header-height} - 24px) / 2) 0;
			margin-right: 8px;
			color: $primary-color;
			vertical-align: top;
			background: white;
		}
	}
}